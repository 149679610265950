import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { UIRoutes } from "../../constants";
import { useInviteCodeState } from "../../../state/context/contextHooks.state";
import { InviteCodeGuardProps } from "./types";

export const InviteCodeGuard: React.FC<InviteCodeGuardProps> = ({
	children,
}) => {
	const { loggedInWithInviteCode } = useInviteCodeState();

	return loggedInWithInviteCode === true ? (
		(children as ReactElement)
	) : (
		<Navigate to={UIRoutes.LANDING} />
	);
};
