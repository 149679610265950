import ReactDOM from "react-dom/client";
import React, { useEffect } from "react";
// import App from "./App";
import "@fontsource/inter";
import "./index.css";
import { AuthStateProvider } from "./state/context/AuthState.context";
import { FantasyStocksRouter } from "./router/FantasyStocksRouter.router";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<AuthStateProvider>
			<FantasyStocksRouter />
		</AuthStateProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
