export enum HttpStatusCode {
	// Successful Responses
	OK = 200, // Login successful
	Created = 201, // Account creation successful

	// Client Error Responses
	BadRequest = 400, // Bad request (e.g., malformed request, invalid password format)
	Unauthorized = 401, // Incorrect login credentials
	Forbidden = 403, // Access to a resource is forbidden
	NotFound = 404, // Resource not found (e.g., account does not exist)
	Conflict = 409, // Conflict (e.g., duplicate account)

	// Server Error Responses
	InternalServerError = 500, // Generic server error
	ServiceUnavailable = 503, // Service unavailable (e.g., server overload, maintenance)
}
