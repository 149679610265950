import React, { Context, createContext } from "react";
import { InviteCodeManager } from "../authState/InviteCode/types";
import { useInviteCodeManager } from "../authState/InviteCode/InviteCode.state";
import { HttpStatusCode } from "../authState/constants";
import { AuthStateContext, AuthStateContextProviderProps } from "./types";
import {
	AccountLoginManager,
	AccountLoginSubmission,
} from "../authState/AccountLogin/types";
import { useAccountLoginManager } from "../authState/AccountLogin/AccountLogin.state";
import {
	AccountSignUpManager,
	SignUpRequestPayload,
} from "../authState/AccountSignUp/types";
import { useAccountSignUpManager } from "../authState/AccountSignUp/AccountSignUp.state";

export const AuthState: Context<AuthStateContext> =
	createContext<AuthStateContext>({
		accountSignUpState: {
			accountSubmissionSucceeds: false,
			accountSubmissionFails: false,
			loadingFields: true,
			submittingUser: false,
			authResponse: null,
			signUpFields: [],
			handleUserSubmission: async () => undefined,
		},
		accountSignInState: {
			loggedInWithAccount: false,
			accountLoginIncorrect: false,
			authResponseLogin: null,
			handleAccountLogin: () => undefined,
			logoutAccount: () => null,
		},
		inviteCodeState: {
			loggedInWithInviteCode: false,
			submissionAttemptIncorrect: false,
			authResponseInviteCode: null,
			handleUserSubmission: () => null,
			logoutInviteCode: () => null,
		},
		logoutAccountAndInviteCode: () => null,
	});

export const AuthStateProvider: React.FC<AuthStateContextProviderProps> = ({
	children,
}) => {
	const inviteCodeState: InviteCodeManager = useInviteCodeManager(
		async (submittedInviteCode: string) => ({
			statusCode:
				submittedInviteCode === "FANTASY"
					? HttpStatusCode.OK
					: HttpStatusCode.NotFound,
		})
	);

	const accountSignInState: AccountLoginManager = useAccountLoginManager(
		async (submittedLogin: AccountLoginSubmission) => ({
			statusCode:
				JSON.stringify(submittedLogin) ===
				JSON.stringify({ userName: "FANTASY", password: "STOCKS" })
					? HttpStatusCode.OK
					: HttpStatusCode.NotFound,
		})
	);

	const accountSignUpState: AccountSignUpManager = useAccountSignUpManager(
		async () => ({
			statusCode: HttpStatusCode.OK,
			accountSignUpFields: [
				"firstName",
				"lastName",
				"email",
				"date of birth",
				"password",
			].map((fieldName: string) => ({
				fieldName,
				fieldFormatInstructions: "value must not be empty",
			})),
		}),

		async (signUpFields: SignUpRequestPayload) => {
			const { fieldsToSubmit: fieldsSubmitted } = signUpFields;
			const validationResults = fieldsSubmitted.map((field) => ({
				fieldSubmissionFormatValid: field.value ? true : false,
			}));

			const statusCode = validationResults.find(
				(result) => result.fieldSubmissionFormatValid === false
			)
				? HttpStatusCode.BadRequest
				: HttpStatusCode.OK;

			return {
				statusCode,
				fieldFormatValidationStateList: validationResults,
			};
		}
	);

	const logoutAccountAndInviteCode: () => void = () => {
		accountSignInState.logoutAccount();
		inviteCodeState.logoutInviteCode();
	};

	return (
		<AuthState.Provider
			value={{
				accountSignUpState,
				accountSignInState,
				inviteCodeState,
				logoutAccountAndInviteCode,
			}}>
			{children}
		</AuthState.Provider>
	);
};
