import React from "react";
import { Landing } from "../pages/Landing/Landing";
import { InviteCodeGuard } from "./routerComponents/InviteCodeGuard/InviteCodeGuard.component";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UIRoutes } from "./constants";
import { Login } from "../pages/Login/Login.page";
import { AccountGuard } from "./routerComponents/AccountGuard/AccountGuard.component";
import { LandingForMembers } from "../pages/LandingForMembers/LandingForMembers.page";
import { SignUp } from "../pages/SignUp/SignUp.page";
import { Header } from "../components/Header/Header.component";
import { Footer } from "../components/Footer/Footer.component";

export const FantasyStocksRouter: React.FC = () => {
	return (
		<BrowserRouter>
			<div id='main-content'>
				<Header />
				<div id='page'>
					<Routes>
						<Route
							path={UIRoutes.LOGIN}
							element={
								<InviteCodeGuard>
									<Login />
								</InviteCodeGuard>
							}
						/>
						<Route
							path={UIRoutes.SIGNUP}
							element={
								<InviteCodeGuard>
									<SignUp />
								</InviteCodeGuard>
							}
						/>
						<Route
							path={UIRoutes.MEMBERS_LANDING}
							element={
								<AccountGuard>
									<LandingForMembers />
								</AccountGuard>
							}
						/>
						<Route path={UIRoutes.LANDING} element={<Landing />} />
					</Routes>
				</div>
				<Footer />
			</div>
		</BrowserRouter>
	);
};
