import { useState } from "react";
import { AuthResponse } from "../types";
import { HttpStatusCode } from "../constants";
import { localStorageInviteCodeParam } from "./constants";
import { InviteCodeManager } from "./types";

export const useInviteCodeManager = (
	inputVerifyInviteCode: (submittedInviteCode: string) => Promise<AuthResponse>
): InviteCodeManager => {
	const [loggedInWithInviteCode, setLoggedInWithInviteCode] = useState(
		localStorage.getItem(localStorageInviteCodeParam) === "true"
	);

	const [submissionAttemptIncorrect, setSubmissionAttemptIncorrect] =
		useState(false);

	const [authResponseInviteCode, setAuthReponseInviteCode] =
		useState<AuthResponse | null>(null);

	const verifyInviteCode: (
		submittedInviteCode: string
	) => Promise<AuthResponse> = inputVerifyInviteCode;

	const handleUserSubmission = async (userSubmission: string) => {
		const authResponse: AuthResponse = await verifyInviteCode(userSubmission);
		const inviteCodeLoginSucceeds =
			authResponse.statusCode === HttpStatusCode.OK;
		const inviteCodeLoginFails = !inviteCodeLoginSucceeds;

		setAuthReponseInviteCode(authResponse);

		if (inviteCodeLoginSucceeds === true) {
			setSubmissionAttemptIncorrect(false);
			setLoggedInWithInviteCode(inviteCodeLoginSucceeds);
			localStorage.setItem(localStorageInviteCodeParam, "true");
		} else {
			setSubmissionAttemptIncorrect(inviteCodeLoginFails);
		}
	};

	const logoutInviteCode = () => {
		resetState();
	};

	const resetState = () => {
		localStorage.removeItem(localStorageInviteCodeParam);
		setAuthReponseInviteCode(null);
		setSubmissionAttemptIncorrect(false);
		setLoggedInWithInviteCode(false);
	};

	return {
		loggedInWithInviteCode,
		submissionAttemptIncorrect,
		authResponseInviteCode,
		handleUserSubmission,
		logoutInviteCode,
	};
};
