import { useState } from "react";
import { AuthResponse } from "../types";
import { HttpStatusCode } from "../constants";
import { AccountLoginManager, AccountLoginSubmission } from "./types";
import { localStorageAccountLoggedInParam } from "./constants";

export const useAccountLoginManager = (
	inputVerifyAccountLogin: (
		submittedLogin: AccountLoginSubmission
	) => Promise<AuthResponse>
): AccountLoginManager => {
	const [loggedInWithAccount, setLoggedInWithAccount] = useState(
		localStorage.getItem(localStorageAccountLoggedInParam) === "true"
	);

	const [accountLoginIncorrect, setAccountLoginIncorrect] = useState(false);

	const [authResponseLogin, setAuthResponseLogin] =
		useState<AuthResponse | null>(null);

	const verifyLoginAttempt: (
		submittedLogin: AccountLoginSubmission
	) => Promise<AuthResponse> = inputVerifyAccountLogin;

	const handleAccountLogin = async (
		userSubmission: AccountLoginSubmission
	) => {
		const authResponse: AuthResponse = await verifyLoginAttempt(
			userSubmission
		);
		const loginSucceeds = authResponse.statusCode === HttpStatusCode.OK;
		const loginFails = !loginSucceeds;

		setAuthResponseLogin(authResponse);

		if (loginSucceeds === true) {
			setAccountLoginIncorrect(false);
			setLoggedInWithAccount(loginSucceeds);
			localStorage.setItem(localStorageAccountLoggedInParam, "true");
		} else {
			setAccountLoginIncorrect(loginFails);
		}
	};

	const logoutAccount = () => {
		resetState();
	};

	const resetState = () => {
		localStorage.removeItem(localStorageAccountLoggedInParam);
		setAuthResponseLogin(null);
		setAccountLoginIncorrect(false);
		setLoggedInWithAccount(false);
	};

	return {
		loggedInWithAccount,
		accountLoginIncorrect,
		authResponseLogin,
		handleAccountLogin,
		logoutAccount,
	};
};
