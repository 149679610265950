import { Box } from "@mui/joy";
import React from "react";
import { UIRoutes } from "../../router/constants";
import { Link } from "react-router-dom";

export const LandingForMembers: React.FC = () => {
	return (
		<Box className='landing-for-members-page'>
			<Link to={UIRoutes.LOGIN}>
				Congratulations for logging into your account! Now go back to the
				login page
			</Link>
		</Box>
	);
};
