/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { InviteCodeManager } from "../../state/authState/InviteCode/types";
import { AccountLoginManager } from "../../state/authState/AccountLogin/types";
import {
	useAccountState,
	useInviteCodeState,
	useLogoutAccountAndInviteCodeFunction,
} from "../../state/context/contextHooks.state";
import { UIRoutes } from "../../router/constants";
import { Link } from "react-router-dom";

export const Header: React.FC = () => {
	const { loggedInWithInviteCode, logoutInviteCode }: InviteCodeManager =
		useInviteCodeState();

	const { loggedInWithAccount }: AccountLoginManager = useAccountState();

	const logoutAccountAndInviteCode = useLogoutAccountAndInviteCodeFunction();

	return (
		<header className='header' style={{ display: "flex", columnGap: "1em" }}>
			{loggedInWithInviteCode && !loggedInWithAccount && (
				<>
					<Link
						to='#'
						onClick={(e) => {
							e.preventDefault();
							logoutInviteCode();
						}}>
						sign-out of invite session
					</Link>
					<Link to={UIRoutes.LOGIN}>login</Link>
					<Link to={UIRoutes.SIGNUP}>signup</Link>
				</>
			)}
			{loggedInWithInviteCode && loggedInWithAccount && (
				<Link
					to='#'
					onClick={(e) => {
						e.preventDefault();
						logoutAccountAndInviteCode();
					}}>
					logout
				</Link>
			)}
			<Link to={UIRoutes.LANDING}>main landing</Link>
		</header>
	);
};
