import React, { useRef } from "react";
import { Button, Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import { UIRoutes } from "../../router/constants";
import { Logo } from "../../components/SVG/Logo/Logo.component";
import { SVGDimensionUnits } from "../../components/SVG/constants";
import { InviteCodeManager } from "../../state/authState/InviteCode/types";
import { useInviteCodeState } from "../../state/context/contextHooks.state";

const buttonStyles = {
	width: "300px",
	marginTop: "40px",
};

export const Landing: React.FC = () => {
	const {
		loggedInWithInviteCode,
		submissionAttemptIncorrect,
		handleUserSubmission,
	}: InviteCodeManager = useInviteCodeState();

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<div>
				<div
					style={{
						margin: "0 auto",
						display: "block",
						width: "140px",
					}}>
					<Logo width={140} height={140} units={SVGDimensionUnits.PX} />
				</div>

				<Typography
					level='h3'
					className='jost-finimmerse'
					sx={{ textAlign: "center", color: "#fff" }}>
					FINIMMERSE
				</Typography>
				<div className='user-actions'>
					{loggedInWithInviteCode === true ? (
						<UserAuthLinks />
					) : (
						<InviteCodeSubmissionField
							handleInviteCodeValidation={handleUserSubmission}
							submissionInvalid={submissionAttemptIncorrect}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const InviteCodeSubmissionField = ({
	handleInviteCodeValidation,
	submissionInvalid,
}) => {
	const inviteCodeFieldRef = useRef<HTMLInputElement>(
		document.getElementById("invite-code-field") as HTMLInputElement
	);

	return (
		<div className='invite-code-entry' style={{ marginTop: "2em" }}>
			<label htmlFor='invite-code-field' style={{ marginRight: "1em" }}>
				{submissionInvalid ? "invalid invite-code, please try again" : ""}
			</label>
			<input
				type='text'
				id='invite-code-field'
				ref={inviteCodeFieldRef}
				style={{ marginRight: "2em" }}
				placeholder='Enter invite code...'
			/>
			<a
				className='invite-code-submit'
				style={{
					cursor: "pointer",
					color: "white",
					textDecoration: "underline",
				}}
				onClick={() =>
					handleInviteCodeValidation(inviteCodeFieldRef.current.value)
				}>
				submit invite code
			</a>
		</div>
	);
};

const UserAuthLinks = () => {
	return (
		<div className='user-auth-links'>
			<div>
				<Link to={UIRoutes.SIGNUP}>
					<Button color='success' sx={buttonStyles}>
						Create a new account
					</Button>
				</Link>
			</div>
			<div>
				<Link to={UIRoutes.LOGIN}>
					<Button variant='outlined' sx={buttonStyles}>
						Sign In
					</Button>
				</Link>
			</div>
		</div>
	);
};
