/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
	AccountSignUpField,
	AccountSignUpFieldFetchResponse,
	AccountSignUpManager,
	SignUpRequestPayload,
	UIAccountSignUpField,
	UserSubmissionAuthResponse,
} from "./types";
import { HttpStatusCode } from "../constants";

export const useAccountSignUpManager = (
	fieldLoader: () => Promise<AccountSignUpFieldFetchResponse>,
	userSubmitter: (
		signUpFields: SignUpRequestPayload
	) => Promise<UserSubmissionAuthResponse>
): AccountSignUpManager => {
	const [signUpState, setSignUpState] = useState<AccountSignUpManager>({
		accountSubmissionSucceeds: false,
		accountSubmissionFails: false,
		loadingFields: true,
		submittingUser: false,
		authResponse: null,
		signUpFields: [],
		handleUserSubmission: async () => undefined,
	});

	const updateSignUpState = (input: Partial<AccountSignUpManager>) => {
		setSignUpState({ ...signUpState, ...input });
	};

	//NOTE!!!!! we expect and require the server side validation code to return the array of
	//validation results per field in the order the fields are submitted
	const handleUserSubmission = async () => {
		const { signUpFields } = signUpState;

		updateSignUpState({ submittingUser: true });

		const requestPayload: SignUpRequestPayload = {
			fieldsToSubmit: signUpFields.map((field: UIAccountSignUpField) => ({
				fieldName: field.fieldName,
				fieldFormatInstructions: field.fieldFormatInstructions,
				value: field.value,
			})),
		};

		const { statusCode, fieldFormatValidationStateList } =
			await userSubmitter(requestPayload);

		signUpState.signUpFields.forEach((field: UIAccountSignUpField, index) => {
			field.formatValidationState = fieldFormatValidationStateList[index];
		});

		updateSignUpState({
			accountSubmissionSucceeds: statusCode === HttpStatusCode.OK,
			accountSubmissionFails: statusCode !== HttpStatusCode.OK,
			authResponse: { statusCode },
			signUpFields: [...signUpState.signUpFields],
			submittingUser: false,
		});
	};

	useEffect(() => {
		fieldLoader().then(
			(fieldFetchResponse: AccountSignUpFieldFetchResponse) => {
				const { statusCode, accountSignUpFields } = fieldFetchResponse;
				const uiFields: UIAccountSignUpField[] = accountSignUpFields.map(
					(suField: AccountSignUpField, index) => {
						return {
							...suField,
							value: "",
							formatValidationState: null,
							updateValueState: (changedValue) => {
								setSignUpState((prevState) => {
									const updatedFields = [...prevState.signUpFields];
									updatedFields[index].value = changedValue;
									return { ...prevState, signUpFields: updatedFields };
								});
							},
						};
					}
				);

				updateSignUpState({
					authResponse: { statusCode },
					signUpFields: uiFields,
					loadingFields: false,
				});

				return signUpState;
			}
		);
	}, []);

	return {
		...signUpState,
		handleUserSubmission,
	};
};
