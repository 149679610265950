import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { UIRoutes } from "../../constants";
import { AccountGuardProps } from "./types";
import { useAccountState } from "../../../state/context/contextHooks.state";

export const AccountGuard: React.FC<AccountGuardProps> = ({ children }) => {
	const { loggedInWithAccount } = useAccountState();

	return loggedInWithAccount === true ? (
		(children as ReactElement)
	) : (
		<Navigate to={UIRoutes.LOGIN} />
	);
};
