import React from "react";
import {
	Box,
	FormControl,
	FormLabel,
	Input,
	Button,
	FormHelperText,
	CircularProgress,
} from "@mui/joy";
import { useSignUpState } from "../../state/context/contextHooks.state";
import {
	AccountSignUpManager,
	UIAccountSignUpField,
} from "../../state/authState/AccountSignUp/types";

export const SignUp: React.FC = () => {
	const {
		accountSubmissionSucceeds,
		accountSubmissionFails,
		loadingFields,
		submittingUser,
		signUpFields,
		handleUserSubmission,
	}: AccountSignUpManager = useSignUpState();

	return (
		<Box
			className='sign-up-page'
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				rowGap: "3em",
			}}>
			{(loadingFields || submittingUser) === true ? (
				<CircularProgress />
			) : (
				<Box
					className='sign-up-controls'
					sx={{
						display: "flex",
						flexDirection: "column",
						rowGap: "2em",
						width: "25%",
					}}>
					<Box className='temp-info'>
						NOTE: for now until we get an accounts db table the account
						you create here will not be real nor persisted. Nothing
						special will happen when you successfully 'create' a new
						account other than a positive message. We could create some
						persistence on the frontend to mock but that would be
						additional work that would have to be reverted shortly as we
						now have an actual database
					</Box>
					{signUpFields.map((signUpField: UIAccountSignUpField, index) => (
						<FormControl key={index}>
							<FormLabel sx={{ color: "white" }}>
								{signUpField.fieldName}
							</FormLabel>
							<FormLabel
								sx={{
									color:
										signUpField.formatValidationState
											?.fieldSubmissionFormatValid === false
											? "red"
											: "yellow",
								}}>
								{signUpField.fieldFormatInstructions}
							</FormLabel>
							<Input
								value={signUpField.value}
								onChange={(e) =>
									signUpField.updateValueState(e.target.value)
								}
								variant='outlined'
								color={
									signUpField.formatValidationState
										?.fieldSubmissionFormatValid === false
										? "danger"
										: "neutral"
								}
							/>
						</FormControl>
					))}
					<Button
						type='submit'
						onClick={() => {
							handleUserSubmission();
						}}>
						Submit User
					</Button>
					{accountSubmissionFails && (
						<FormHelperText sx={{ color: "red" }}>
							Failed to create account, please try again
						</FormHelperText>
					)}
					{accountSubmissionSucceeds && (
						<FormHelperText sx={{ color: "green" }}>
							Congratulations! You've created a new account
						</FormHelperText>
					)}
				</Box>
			)}
		</Box>
	);
};
