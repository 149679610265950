import { Grid } from "@mui/joy";
import React from "react";

export const Footer: React.FC = () => (
	<div id='footer'>
		<hr style={{ width: "60%" }} />
		<div
			style={{
				width: "60%",
				display: "block",
				margin: "0 auto",
				textAlign: "left",
			}}>
			<Grid container spacing={2}>
				<Grid xs={6} md={8}>
					<span>Company</span>
					<span>&nbsp;|&nbsp;</span>
					<span>About</span>
				</Grid>
				<Grid xs={6} md={4}>
					<div style={{ textAlign: "right" }}>Legal</div>
				</Grid>
			</Grid>
		</div>
	</div>
);
