import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	Input,
} from "@mui/joy";
import { AccountLoginManager } from "../../state/authState/AccountLogin/types";
import { useAccountState } from "../../state/context/contextHooks.state";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UIRoutes } from "../../router/constants";

export const Login: React.FC = () => {
	const {
		loggedInWithAccount,
		accountLoginIncorrect,
		handleAccountLogin,
	}: AccountLoginManager = useAccountState();

	const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [userClickedSubmit, setUserClickedSubmit] = useState(false);
	//this state is to not trick the useEffect into sending us back to the members landing page
	//when a user already logged in comes back here for some reason
	const navigate = useNavigate();

	useEffect(() => {
		if (loggedInWithAccount === true && userClickedSubmit === true) {
			navigate(UIRoutes.MEMBERS_LANDING);
		}
	}, [userClickedSubmit, loggedInWithAccount]);

	return (
		<Box
			className='login-page'
			sx={{
				display: "flex",
				"justify-content": "center",
				"align-items": "center",
				"row-gap": "3em",
			}}>
			{loggedInWithAccount === true ? (
				<Link to={UIRoutes.MEMBERS_LANDING}>Back to Member's Landing</Link>
			) : (
				<Box
					className='login-controls'
					sx={{
						display: "flex",
						"flex-direction": "column",
						"row-gap": "1em",
					}}>
					<FormControl>
						<FormLabel sx={{ color: "white" }}>
							username hint:its FANTASY{" "}
						</FormLabel>
						<Input
							onChange={(e) => setUserName(e.target.value)}
							variant='outlined'
							color={
								accountLoginIncorrect === true ? "danger" : "neutral"
							}
						/>
					</FormControl>
					<FormControl>
						<FormLabel sx={{ color: "white" }}>
							username hint:its STOCKS{" "}
						</FormLabel>
						<Input
							onChange={(e) => setPassword(e.target.value)}
							variant='outlined'
							color={
								accountLoginIncorrect === true ? "danger" : "neutral"
							}
						/>
					</FormControl>
					<Button
						type='submit'
						onClick={() => {
							setUserClickedSubmit(true);
							handleAccountLogin({
								userName: username,
								password: password,
							});
						}}>
						LOGIN
					</Button>
					{accountLoginIncorrect && (
						<FormHelperText sx={{ color: "red" }}>
							Incorrect username or password, please try again
						</FormHelperText>
					)}
					<Box className='link-to-sign-up'>
						<Link to={UIRoutes.SIGNUP}>new account</Link>
					</Box>
				</Box>
			)}
		</Box>
	);
};
