import { useContext } from "react";
import { AccountLoginManager } from "../authState/AccountLogin/types";
import { AuthState } from "./AuthState.context";
import { InviteCodeManager } from "../authState/InviteCode/types";
import { AccountSignUpManager } from "../authState/AccountSignUp/types";

export const useAccountState = (): AccountLoginManager => {
	const { accountSignInState } = useContext(AuthState);

	return accountSignInState;
};

export const useInviteCodeState = (): InviteCodeManager => {
	const { inviteCodeState } = useContext(AuthState);

	return inviteCodeState;
};

export const useSignUpState = (): AccountSignUpManager => {
	const { accountSignUpState } = useContext(AuthState);

	return accountSignUpState;
};

//DOES NOT RETURN REACT STATE
export const useLogoutAccountAndInviteCodeFunction = () => {
	const { logoutAccountAndInviteCode } = useContext(AuthState);

	return logoutAccountAndInviteCode;
};
