export enum SVGDimensionUnits {
	EM = "em",
	EX = "ex",
	PX = "px",
	PT = "pt",
	PC = "pc",
	CM = "cm",
	MM = "mm",
	IN = "in",
	PERCENT = "%",
}